import { useTranslation } from 'react-i18next';
import { Cell } from './Cell';
import type { PreOrderStatusDto } from '~/lib/dtos/common/PreOrderStatusDto';

type Props = {
  preOrderStatus: PreOrderStatusDto;
};

export const PreOrderStatusCell = ({ preOrderStatus }: Props) => {
  const orderStatusMapped = usePreOrderStatusMap({
    preOrderStatus,
  });

  return <Cell justifyText="center" content={orderStatusMapped} />;
};

const usePreOrderStatusMap = ({
  preOrderStatus,
}: Parameters<typeof PreOrderStatusCell>[number]) => {
  const { t } = useTranslation();

  const preOrderStatusMap: Record<PreOrderStatusDto, string> = {
    CANCELLED: t('pre-order-status.cancelled'),
    CLOSED_WITH_OPERATOR_TIMEOUT: t(
      'pre-order-status.closed-with-operator-timeout'
    ),
    COMPLETED: t('pre-order-status.completed'),
    ABANDONED: t('pre-order-status.abandoned'),
    DENIED: t('pre-order-status.denied'),
    WAITING_FOR_HINTS_REPLY: t('pre-order-status.hints'),
    WAITING_FOR_HINTS_REPLY_REPEATED_ORDER: t(
      'pre-order-status.hints-repeated-order'
    ),
    WAITING_FOR_SECOND_HINTS_REPLY: t('pre-order-status.second-hints'),
  };

  return preOrderStatus
    ? preOrderStatusMap[preOrderStatus]
    : t('common.invalid-order-error');
};
