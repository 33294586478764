import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useModal } from '~/hooks/useModal';
import { Auth } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import NotyfContext from '~/context/NotyfContext';
import { NoteModal } from '~/components/Modal/NoteModal';
import { useDecreaseCustomerWarningMutation } from '~/lib/hooks/useDecreaseCustomerWarningMutation';
import { UserDto } from '~/lib/dtos/UserDto';
import Button from '~/components/Button';
import { useHasPermissions } from '~/hooks/useHasPermissions';

type Props = {
  user: UserDto['user'];
};
export const DecreaseWarningsCell = ({ user }: Props) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const { decreaseCustomerWarning } = useDecreaseCustomerWarningMutation();
  const [isModalOpen, setIsModalOpen] = useModal();

  const isUserBlocked = user.isBlocked;
  const hasWarnings = user.warningCount > 0;
  const hasDecreaseWarningPermission = useHasPermissions('DECREASE_WARNING');

  const canDecreaseWarning =
    !isUserBlocked && hasWarnings && hasDecreaseWarningPermission;

  const handleDecreaseWarning = (note: string) => {
    decreaseCustomerWarning.mutate(
      { customerId: user.userId.value, note },
      {
        onSuccess: () => setIsModalOpen(),
      }
    );
  };

  const handleDecreaseWarningClick = () => {
    if (!canDecreaseWarning) {
      if (!hasWarnings) {
        notyf.error(t('customer.no-warnings-to-remove'));
        return;
      }
      if (isUserBlocked) {
        notyf.error(
          t('customer.you-cannot-remove-warnings-from-a-blocked-user')
        );
        return;
      }
      if (hasDecreaseWarningPermission) {
        //TODO: https://siilisolutions.atlassian.net/browse/DIAL-1355
        return;
      }
    }
    setIsModalOpen();
  };

  return (
    <Table.Cell className="border-2 border-gray-100">
      <Button
        className={canDecreaseWarning ? '' : 'disabled'}
        type={canDecreaseWarning ? 'default' : 'gray'}
        onClick={() => handleDecreaseWarningClick()}
      >
        {t('customer.decrease-warning')}
      </Button>
      <NoteModal
        isVisible={isModalOpen}
        toggleVisibility={setIsModalOpen}
        isLoading={decreaseCustomerWarning.isLoading}
        onSubmit={handleDecreaseWarning}
        submitText={t('customer.decrease-warning')}
        title={t('customer.decrease-warning')}
      >
        <div className="pb-2">
          {`${t('customer.decrease-warning-description')} ${
            user.warningCount - 1
          } .`}
        </div>
      </NoteModal>
    </Table.Cell>
  );
};
