import z from 'zod';

export const OrderStatusDtoSchema = z.union([
  z.literal('WAITING_FOR_CUSTOMER_CONFIRMATION'),
  z.literal('WAITING_FOR_CUSTOMER_CONFIRMATION_REPEATED_ORDER'),
  z.literal('WAITING_FOR_CUSTOMER_CONFIRMATION_SECOND_ATTEMPT'),
  z.literal('WAITING_FOR_AUTHORIZATION'),
  z.literal('LACK_OF_CONFIRMATION'),
  z.literal('AUTHORIZATION_FAILED'),
  z.literal('CANCELLED'),
  z.literal('COMPLETED'),
  z.literal('TERMINATED_WRONG_CONFIRMATION'),
  z.literal('CLOSED_WITH_OPERATOR_TIMEOUT'),
]);

export type OrderStatusDto = z.infer<typeof OrderStatusDtoSchema>;
